<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="z-index:100!important;">
      <div class="modal-container"
           v-if="getModalInfo"
           @click.stop>
        <div class="close-div">
          <img @click="hide()" style="opacity: 1; cursor:pointer;width: 24px; height: 24px;"
               src="https://gcdn.bionluk.com/site/icon/ic_cancel@3x.png">
        </div>

        <div style="width: 960px; margin: 0 auto; ">


            <div style=" text-decoration: none!important;">
              <div v-if="style_type !== 'list'" :class="['cardA1', 'generalCard', 'card_'+style_type, color]" :style="'background-size: cover; position: relative; background-repeat: no-repeat; background-position: center; background-image: url('+image_url+');'">
                <div v-if="style_type === 'bottom_title'" class="bottom_text_placeholder">
                  <p class="subheader">{{subheader}}</p>
                  <p class="title" v-html="header"></p>
                  <p class="subheader2">{{subheader2}}</p>
                </div>
                <div v-if="style_type !== 'long_title'">
                  <p class="subheader">{{subheader}}</p>
                  <p class="title" v-html="header"></p>
                  <p class="subheader2">{{subheader2}}</p>
                </div>
              </div>
              <div v-if="style_type === 'list'" :class="['generalCard', 'card_'+style_type, color]">
                <p class="subheader">{{subheader}}</p>
                <p class="title">{{header}}</p>
              </div>
            </div>

          <div class="blog-bread" v-if="style_type !== 'list' && blog_sub_category_id">
            <div>
              <router-link to="/blog" class="item" target="_blank">Tüm Yazılar</router-link>
              <img src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg">
              <router-link :to="'/blog/kategori/'+blog_category_slug"  class="item" target="_blank">{{ blog_category_name }}</router-link>
              <img src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg">
              <router-link :to="'/blog/kategori/'+blog_category_slug+'/'+blog_sub_category_slug" class="item" target="_blank">{{blog_sub_category_name}}</router-link>
            </div>
            <div style="margin-top: 10px;">
              <router-link  :to="'/blog/kategori/'+blog_category_slug+'/'+label.slug" v-for="(label, lindex) in labels" :key="lindex" style="padding-right:10px;color: #2cad87; font-size: 14px; font-weight: 600" target="_blank">#{{ label.title }}</router-link>
            </div>

          </div>


            <h1 v-if="style_type === 'long_title'" class="long-main-title">{{header}}</h1>

            <div class="blogContent">

              <div class="blogInside">

                  <span  v-for="(list, index) in htmlList" :key="index">

                    <div v-if="list.split(':portfolio:')[1]">
                      <Portfolio :uuid="list.split(':portfolio:')[1]"/>
                    </div>

                    <div v-else-if="list.split(':gig:')[1]">
                      <List :slug="blog_slug" :impression-u-u-i-d="impressionUUID" type="gig" :list="list.split(':gig:')[1]"/>
                    </div>

                    <div v-else-if="list.split(':username:')[1]">
                      <List :slug="blog_slug" :impression-u-u-i-d="impressionUUID" type="username" :blogID="blogID" :list="list.split(':username:')[1]"/>
                    </div>

                       <div v-else-if="list.split(':imageText:')[1]">
                       <template v-if="list.split(':imageText:')[1].split('--')">
                        <p class="caption-link">
                        {{  list.split(':imageText:')[1].split('--')[0] }}
                            <template v-if="list.split(':imageText:')[1].split('--')">
                               <router-link :to="'/'+list.split(':imageText:')[1].split('--')[1]" class="caption-a">
                              {{list.split(':imageText:')[1].split('--')[1]}}
                                </router-link>
                          </template>
                       </p>
                       </template>
                      <template v-else>
                         <p class="caption-link">{{ list.split(':imageText:')[1]}}</p>
                      </template>

                  </div>

                    <p v-else v-html="list"></p>
                  </span>

              </div>




              <div class="hiddendiv"></div>
              <div class="freelancer-list">
                <div v-for="(list, index) in renderedLists" :key="index" class="freelancer-list-container">
                  <div class="freelancer-list-div">
                    <div :class="'freelancer-list-div-div_'+list.type">
                      <router-link

                        @contextmenu.native="handleGlobalClick($store.state.componentMap.page, null, list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
                        @click.native="handleGlobalClick($store.state.previousComponentPage, null, list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"

                        target="_blank" :to="list.action.url">
                        <img v-if="list.type == 'gig'" class="freelancer-list-avatar" :src="list.image_url"/>
                        <img v-if="list.type == 'user'" class="freelancer-list-avatar_s" :src="list.image_url"/>
                      </router-link>
                    </div>
                    <div class="freelancer-list-second-flex">
                      <router-link

                        @contextmenu.native="handleGlobalClick($store.state.componentMap.page, null, list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
                        @click.native="handleGlobalClick($store.state.previousComponentPage, null, list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"

                        class="nounderlinehover" target="_blank" :to="list.action.url">
                      <p v-if="list.type == 'user'" class="freelancer-list-username">{{list.title}}</p>
                      <p v-if="list.type == 'gig'" class="freelancer-list-user-title" style="padding-top: 6px;">{{list.title}}</p>
                      <p v-if="list.type == 'user'" class="freelancer-list-user-title_s">{{list.subtitle}}</p>
                      <p v-if="list.type == 'gig'" style="font-size: 14px; font-weight: 500; color: #2d3640; padding-top: 4px;">{{list.subtitle}}</p>
                      </router-link>
                    </div>
                    <div v-if="list.type == 'user'" style="width: 400px; align-self: center; align-items: center; align-content: center; height: 50px; ">
                      <div style="text-align: right;margin-right: 48px; padding-top: 5px;">
                        <span @click="sendMessage(list)" :style="buttonLoading ? 'opacity:0.7': 'opacity:1'" class="send_mes">
                          MESAJ GÖNDER
                        </span>
                      </div>

                    </div>
                    <div v-if="list.type == 'gig'" style=" width: 200px; align-self: center; align-items: center; align-content: center; height: 50px; ">
                      <div style="text-align: right;margin-right: 24px; padding-top: 5px;">
                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page, null, list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
                          @click.native="handleGlobalClick($store.state.previousComponentPage, null, list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"

                          target="_blank" :to="list.action.url" class="nounderlinehover" style=" font-weight:600;background-color: #f0f0f7; border-radius: 120px; font-size: 18px; color: #007aff;  padding: 5px 23px 7px 23px">
                          {{list.action.text}}<span style="font-size: 14px;"> TL</span>
                        </router-link>
                        <p style="color: #8e8e93; font-size: 12px; padding-top: 6px; font-weight: 400;  width: 80px; float: right; text-align: center;">{{list.action.subtext}}</p>
                      </div>


                    </div>
                  </div>
                  <div v-if="index !== renderedLists.length - 1 && list.type == 'gig'" class="freelancer-list-border" ></div>
                  <div v-if="index !== renderedLists.length - 1 && list.type == 'user'" class="freelancer-list-border_s" ></div>
                </div>
              </div>



              <div v-if="footer_div.sellerName !== ''" class="hiddendiv20"></div>
              <div v-if="footer_div.sellerName !== ''" style="padding-top:10px;height: 330px; background-color: #eff1f3; margin-left:-47px; margin-right: -47px;">

                <div style="margin:0 auto; width:100%;text-align:center;">
                  <p v-if="footer_div.external_id_text" style="font-size: 14px; padding-top: 5px; font-weight:400;color: #2d3640">{{ footer_div.external_id_text }}</p>
                  <div v-else class="hiddendiv20"></div>
                  <router-link :to="footer_div.action.url">
                    <img style="height: 124px; width: 124px; border-radius: 20px; margin-top: 25px; " :src="footer_div.seller_avatar_url">
                  </router-link>
                  <p style="color: #007aff; margin-top: 16px; font-weight: 600; font-size: 24px;">{{footer_div.sellerName}}</p>
                  <p style="line-height: 19px; height: 20px; margin-top: 8px; margin-bottom: 16px;
    font-size: 14px; font-weight: 300;

    color: rgb(139, 149, 161);">{{footer_div.seller_title}}</p>

                  <div style="margin-top: 24px;">

                    <router-link :to="footer_div.action.url" class="nounderlinehover" style=" background-color: #007aff;color: white;  font-weight:600;border-radius: 120px; font-size: 16px; cursor: pointer;  padding: 5px 23px 7px 23px">
                      {{footer_div.action.text}}
                    </router-link>

                  </div>
                </div>
              </div>


              <div class="hiddendiv"></div>
              <div class="hiddendiv10"></div>
              <div v-if="$route.path !== '/blog' " style="text-align: center; margin-bottom: 40px;">
                  <router-link to="blog" class="blogGo">
                 Tüm Yazılara Git
                </router-link>
              </div>


              <div style="text-align: center">


                <span @click="showShareButtonDetail" v-if="showShareButton" class="blogShare">
                  <img style="display:inherit!important;width: 16px; margin-right: 5px;" src="https://gcdn.bionluk.com/site/icon/iosShare.png"/>
                  Bu Yazıyı Paylaş
                </span>



                <div v-if="!showShareButton" @click="clickedShareButton">
                  <social-sharing :url="url" inline-template>
                    <div style="margin-top: 20px;">
                      <network network="facebook" style="cursor: pointer;">
                        <img style="display:inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/facebook-box.svg'"/>
                      </network>
                      <network network="linkedin" style="cursor: pointer; margin-left: 10px;">
                        <img style="display:inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/linkedin-box.svg'"/>
                      </network>
                      <network network="twitter" style="cursor: pointer; margin-left: 10px;">
                        <img style="display:inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/twitter-box.svg'"/>
                      </network>
                    </div>
                  </social-sharing>
                </div>


              </div>

              <div class="hiddendiv"></div>


            </div>
        </div>

      </div>
    </div>
  </transition>

</template>

<script>
  import Portfolio from "../../components/todayItems/portfolio.vue"
  import List from "../../components/todayItems/list.vue"

  export default {
    name: "src-pages-modals-blogDetail-v1",
    components: { Portfolio, List },
    data() {
      return {
        impressionUUID:null,
	      buttonLoading:false,
        showShareButton:true,
        pageLoaded: false,
        blogID: null,
        name: null,
        content: null,
        brief: null,
        commentsCount: null,
        createdAt: null,
        image_url: null,
        image_url_list: null,
        image_url_mobile: null,
        url: '',
        comments: [],
        newComment: '',
        blogLang: null,
        renderedLists: [],
        style_type: null,
        size_type: null,
        color: null,
        header: null,
        subheader: null,
        subheader2: null,
        footer_div: {
          sellerName:"",
          seler_id:"",
          seller_avatar_url:"",
          seller_title:"",
          action:{
            text:"",
            action:"",
            url:""
          }
        },
        htmlList: [],

        blog_category_id:null,
        blog_category_name:null,
        blog_category_slug:null,
        blog_sub_category_id:null,
        blog_sub_category_name:null,
        blog_sub_category_slug:null,
        labels:[],

        blog_slug:'',
      };
    },
    methods: {
	    clickedShareButton(){
		    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'blog share buttons'});
	    },


	    showShareButtonDetail(){

        this.showShareButton = false;
      },
      showPortfolioDetailModal(uuid) {
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: uuid}});
      },
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      setInitialData(data) {

	      this.blog_slug = data.slug;

        let trackGigObj = [];
        let k = 1;
        data.renderedLists.forEach((rList)=> {
          if(rList.type === 'gig'){
            trackGigObj.push({
              gig_id: rList.action.mobile.gig_id,
              seller_id: rList.action.mobile.seller_id,
              order_id: k
            })
            k++;
          }
        });
        if(trackGigObj.length > 0){
          let trackingProps = {
            page:'blogdetail',
            placement:'renderedLists',
            info:trackGigObj,
            term: data.slug,
            title: this.impressionUUID
          };
          this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
        }

        this.Helper.trackEvents.pageView(this.Helper.blogDetailModalProps(data), 'blogdetail', 'v1');

        this.blogID = data.blog_id;
        this.name = data.name;
        this.content = data.description;
        this.brief = data.brief;
        this.image_url = data.image_url;
        this.image_url_list = data.image_url_list;
        this.image_url_mobile = data.image_url_mobile;
        this.commentsCount = data.comment_count;
        this.createdAt = Number(data.created_at);
        this.comments = data.blog_topic_comments;
        this.renderedLists = data.renderedLists;
        this.style_type = data.style_type;
        this.size_type = data.size_type;
        this.color = data.color;
        this.header = data.header;
        this.subheader = data.subheader;
        this.subheader2 = data.subheader2;
        this.footer_div = data.footer_div;
        this.url = window.location.origin +'/blog/'+data.slug;
        this.htmlList = data.htmlList;

        this.blog_category_id = data.blog_category_id;
        this.blog_category_name = data.blog_category_name;
        this.blog_category_slug = data.blog_category_slug;
        this.blog_sub_category_id = data.blog_sub_category_id;
        this.blog_sub_category_name = data.blog_sub_category_name;
        this.blog_sub_category_slug = data.blog_sub_category_slug;
        this.labels = data.labels;

      },

      saveBlogComment() {
        this.saveComment();
        this.api.content.saveBlogComment(this.blogID, this.newComment, this.blogLang)
          .then(({data}) => {
            let result = data;

            if (success) {
              this.$toasted.global.infoToast({description: message});
              this.newComment = "";

            } else {
              this.revertComment();
              this.$toasted.global.errorToast({description: message});
              this.$router.push(data.redirect_url);
            }

          })
          .catch(err => {
            this.revertComment();
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      saveComment() {
        let savedComment = {
          user: {
            username: this.user.username,
            avatar_url: this.user.avatar_url
          },
          comment: this.newComment,
          created_at: this.calculateElapsedTime(+new Date() - 1000)
        };

        this.comments.push(JSON.parse(JSON.stringify(savedComment)));
      },

      revertComment() {
        this.comments.pop();
        this.newComment = "";
      },

      sendMessage(user) {


        if (!this.isLoggedIn) {
          this.$router.push('/login/');
        } else {

        	if(this.buttonLoading === false){
		        this.buttonLoading = true;
		        this.api.workstation.chatOpen(user.action.screenProps.username, 0,1)
			        .then(({data}) => {
				        let result = data;
				        if (result.success) {
					        let conversationSendData = {
						        r_username: user.action.screenProps.username,
						        r_avatar_url: user.image_url,
						        r_id:  user.action.screenProps.id,
						        r_uuid: user.action.screenProps.uuid,
						        fromWhere: 'Blog',
						        c_uuid: result.data.c_uuid,
						        blogID:this.blogID
					        };
					        this.EventBus.$emit('externalOpenChat', conversationSendData);
				        } else {
					        this.$toasted.global.errorToast({description: result.message});
				        }
				        this.buttonLoading = false;
			        })
			        .catch(err => {
				        this.buttonLoading = false;
				        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			        });
          }
        }

      }

    },
    computed: {
      getModalInfo() {
        let info =  this.$store.state.activeModal.info ? this.$store.state.activeModal.info.block :  null;
        if (info) {
          this.setInitialData(info);
        }
        return true;
      },
    },
    created() {
      this.impressionUUID = this.Helper.generateUUID()
    }
  }

</script>

<style scoped lang="scss">


.caption-link{
  font-size:15px; text-align: center; margin-top: 10px; font-weight: 300;
  .caption-a{
    color: #fe5b5c;
    text-decoration: none;
    font-weight: 600;
    &:hover{
      text-decoration: underline;
    }
  }
}


.blog-bread{
  margin-left: 40px; margin-top: 20px;
  .item{
    font-size: 14px;
    text-align: left;
    color: #8b95a1;

  }
  img{
    margin-left: 6px; margin-right: 5px;
  }
}

.long-main-title{
  margin: 15px 40px 5px;
  font-size: 36px;
  font-weight: bold;
  color: #2d3640;


}

  .send_mes{
    cursor: pointer; font-weight:600;background-color: #f0f0f7; border-radius: 120px; font-size: 16px; color: #007aff;  padding: 5px 23px 7px 23px
  }
  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);

  }

  .modal-container {
    font-family: 'sofia-pro';

    padding: 0;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px);
    max-width: 960px;
    max-height: calc(100vh - 40px);
    background-color: #f4f5f7;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    overflow-y: scroll;
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .close-div {
    z-index:1;
    position: absolute;
    top: 30px;
    right: 30px;
  }


  .blogContent{
    text-align: left;
    position: relative;
    background-color: #f4f5f7;
    box-shadow: 0 0px 0px 0 rgba(40, 60, 98, 0.1);
    padding: 0 47px 20px;
  }


  .blogInside{
    font-size: 18px;
    line-height: 2;
    color: #5e6b79;
    margin-top: 30px;
  }





  .generalCard{
    text-align: left;
    position: relative;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    background-color: rgb(255, 255, 255);
    box-shadow: 0 0px 20px 0 rgba(40, 60, 98, 0.1);
  }


  .generalCard:hover{
    text-decoration: none!important;
  }

  .cardA1{
    height: 390px; background-position: center;
  }


  .freelancer-list{
    margin-right: 14px
  }

  .freelancer-list-div{
    display: flex; height: 100px;
  }

  .freelancer-list-div-div_user{
    align-self: center;
    width: 132px;

  }

  .freelancer-list-div-div_gig{
    align-self: center;
    width: 187px;

  }

  .freelancer-list-avatar{
    height:80px;  border:1px solid #dde3eb;border-radius: 4px;
  }

  .freelancer-list-avatar_s{
    height:80px;  border:1px solid #dde3eb;border-radius: 12px;
  }


  .freelancer-list-second-flex{
    margin-left: 32px; height: 80px; align-self: center; width: 500px;
  }

  .freelancer-list-username{padding-top: 5px;
    line-height:36px;font-size: 24px; font-weight: 400; color: rgb(36, 36, 36);
  }

  .freelancer-list-user-title{
    line-height:19px; font-size: 19px; font-style: italic; color: rgb(139, 149, 161);
  }

  .freelancer-list-border{
    margin-left:177px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
  }

  .freelancer-list-border_s{
    margin-left:110px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
  }


  .freelancer-list-user-title_s{
    line-height:19px; font-size: 15px; font-style: italic; color: rgb(139, 149, 161);
  }

  .card_bold .title{

    font-size: 48px; width: 250px;

    bottom: 80px;
    left: 30px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);


  }


  .card_bold .subheader{

    font-weight:400; opacity: 0.6; font-size:20px;line-height: 1.56;
    position: absolute;
    bottom: 48px;
    left: 30px;


  }


  .card_up_title .title{


    font-size: 32px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 40px;


  }


  .card_up_title .subheader{


    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 24px;

  }



  .card_up_title .subheader2{


    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 17px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 24px;
    margin-bottom: 10px;

  }



  .card_list .title{



    font-size: 32px;
    font-weight: 600;
    padding-left: 20px;


    letter-spacing: 0.34px;
    text-align: left;
    line-height: 40px;

  }


  .card_list .subheader{

    opacity: 0.7;
    font-size: 15px;
    letter-spacing: -0.24px;




    padding: 18px 20px 6px 20px;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 24px;
  }



  .card_list .subheader2{

    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 17px;
    opacity:0.7;

    letter-spacing: -0.32px;
    line-height: 24px;
    margin-bottom: 10px;
  }



  .bottom_text_placeholder {

    position: absolute;
    bottom: 16px;
    left: 20px;
    right: 20px;
  }


  .card_bottom_title .subheader{


    z-index: 1;

    font-size: 20px; opacity:0.75;font-weight: 500;
    line-height: 32px;

  }


  .card_bottom_title .title{

    font-size: 32px;
    font-weight: 600;
    line-height: 50px;

  }




  .card_bottom_title .subheader2{

    padding-top: 16px;
    font-size: 18px;
    z-index: 1;
    letter-spacing: -0.32px;
    line-height: 21px;
    opacity:0.6;

  }




  .white{
    color: #fff;
  }

  .black{
    color: #242424;
  }








  .portfolio-modal-review-main {
    width: 600px;
    padding-left: 16px;


  }

  .user-info-div {

    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .title-and-username {
    margin-left: 18px;


    p {
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      color: #2d3640;
    }

    .owner-username {
      margin-top: 2px;
      font-size: 14px;
      line-height: 1.29;
      text-align: left;
      color: #8b95a1;
    }
  }

  .portfolio-modal-big-img {
    width: 700px;
    height: 394px;
    margin-top: 16px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
  }

  .portfolio-modal-small-img {
    width: 672px;
    border-radius: 2px;
    background-color: #f4f5f7;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    p {
      font-size: 15px;
      font-weight: 600;
      text-align: left;
      color: #2d3640;
      margin-top: 15px;
      margin-bottom: 12px;
    }

    .attachments-div {
      &:first-child {
        margin-left: 0;
      }
      img {
        height: 73px;
      }
      height: 73px;
      background-color: #ffffff;
      border: solid 1px #bfc8d2;
      cursor: zoom-in;
      display: inline-block;
      margin-left: 4px;
    }
  }

  .loader-div {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      object-fit: cover
    }

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .playIcon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 60px;
    width: 60px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    img {
      height: 60px;
      width: 60px;
    }
  }



.blogGo{
  background-color: #F3F4F6; width: 140px; padding: 6px 24px  10px;
  color:#007aff;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;

}

.blogGo:hover{
  background-color: #efeff4;
}


  .blogShare{
    background-color: #F3F4F6; width: 140px; padding: 6px 24px  10px;
    color:#007aff;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

  }

  .blogShare:hover{
    background-color: #efeff4;
  }





</style>
